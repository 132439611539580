import fr from '../lang/locales/fr-FR.json'
import nl from '../lang/locales/nl-NL.json'

export default defineI18nConfig(() => ({
  legacy: true,
  silentTranslationWarn: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    fr,
    nl,
  },
  linkedMessages: {
    startDelimiter: '{',
    endDelimiter: '}',
  },
}))
